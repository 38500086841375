export const languages = [{
        languageName: "HTML",
    },
    {
        languageName: "SCSS/CSS",
    },
    {
        languageName: "Javascript",
    },
    {
        languageName: "React",
    },
    {
        languageName: "Angular",
    },
    {
        languageName: "Vue.js",
    },
    {
        languageName: "gsap",
    },
    {
        languageName: "Webpack",
    },
    {
        languageName: "npm",
    }
]